/* Confetti container */
.order-confirmed-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    background-color: #f0fff4;
    overflow: hidden;
  }
  
  .message {
    text-align: center;
    z-index: 1; /* Ensure the message stays above the confetti */
  }
  
  .text-green {
    font-size: 3rem;
    font-weight: bold;
    color: #38a169;
  }
  
  .thank-you {
    font-size: 1.5rem;
    margin-top: 10px;
    color: #48bb78;
  }
  
  /* Confetti animation */
  .confetti {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    z-index: 0; /* Confetti falls behind the message */
  }
  
  .confetti-piece {
    position: absolute;
    width: 10px;
    height: 20px;
    background-color: var(--confetti-color, red);
    opacity: 0.9;
    border-radius: 2px;
    transform-origin: center;
    transform: rotate(0deg) scale(1);
    animation: confetti-fall 4s ease-in-out infinite, confetti-rotate 3s linear infinite;
  }
  
  /* Confetti fall animation */
  @keyframes confetti-fall {
    0% {
      transform: translateY(-100vh) rotate(0deg) scale(0.8);
      opacity: 1;
    }
    100% {
      transform: translateY(100vh) rotate(360deg) scale(1.2);
      opacity: 0;
    }
  }
  
  /* Confetti rotation and scale */
  @keyframes confetti-rotate {
    0% {
      transform: rotate(0deg) scale(1);
    }
    100% {
      transform: rotate(720deg) scale(1.1);
    }
  }
  
  /* Randomize confetti piece colors */
  .confetti-piece:nth-child(odd) {
    --confetti-color: yellow;
  }
  .confetti-piece:nth-child(even) {
    --confetti-color: blue;
  }
  .confetti-piece:nth-child(3n) {
    --confetti-color: green;
  }
  .confetti-piece:nth-child(5n) {
    --confetti-color: orange;
  }
  .confetti-piece:nth-child(7n) {
    --confetti-color: purple;
  }
  
  /* Randomize position, animation duration, delay, scale, and size for each piece */
  .confetti-piece {
    top: calc(100% * var(--random-top));
    left: calc(100% * var(--random-left));
    animation-duration: calc(2s + var(--random-duration) * 4s);
    animation-delay: calc(var(--random-delay) * 2s);
  }
  
  /* Adding randomness for each child */
  .confetti-piece:nth-child(1) {
    --random-top: 0.6;
    --random-left: 0.5;
    --random-duration: 0.2;
    --random-delay: 0.1;
  }
  .confetti-piece:nth-child(2) {
    --random-top: 0.2;
    --random-left: 0.3;
    --random-duration: 0.3;
    --random-delay: 0.2;
  }
  /* Continue to add random properties for remaining confetti pieces */
  .confetti-piece:nth-child(3) {
    --random-top: 0.3;
    --random-left: 0.5;
    --random-duration: 0.4;
    --random-delay: 0.1;
  }
  .confetti-piece:nth-child(4) {
    --random-top: 0.4;
    --random-left: 0.6;
    --random-duration: 0.5;
    --random-delay: 0.3;
  }
  .confetti-piece:nth-child(5) {
    --random-top: 0.8;
    --random-left: 0.6;
    --random-duration: 0.5;
    --random-delay: 0.3;
  }
  .confetti-piece:nth-child(6) {
    --random-top: 0.7;
    --random-left: 0.5;
    --random-duration: 0.5;
    --random-delay: 0.3;
  }
  /* Repeat for more children as needed for the array */
  