
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300..700&display=swap');

.custom-radio-dot {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.custom-radio-dot::before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background-color: transparent;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

input[type="radio"]:checked + .custom-radio-dot::before {
  background-color: #4a3f35; /* Brown color */
}


/* Styles for the slider component */
.range-slider {
  position: absolute;
  height: 10px;
  width: 100%;
  /* background-color: #ddd; */
  border-radius: 5px;
  margin-top: 10px;
}

.range-slider input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  position: absolute;
  background: transparent;
  pointer-events: none; 
}

.range-slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  width: 15px;
  background-color: whitesmoke;
  border-radius: 50%;
  cursor: pointer;
  z-index: 80;
  pointer-events: all; 
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.range-slider input[type="range"]::-moz-range-thumb {
  height: 10px;
  width: 10px;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  pointer-events: all;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.range-slider input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.slider-track {
  position: absolute;
  height: 10px;
  background-color: #D48223;
  border-radius: 5px;
  top: 0;
  z-index: 1;
}


