@import url('https://fonts.googleapis.com/css2?family=Jaldi:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jaldi:wght@400;700&family=Quicksand:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Jaldi:wght@400;700&family=Quicksand:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quattrocento+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities{
  .no-scroll::-webkit-scrollbar{
    display: none;
  }
  .no-scroll{
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #DAD9D9;
}
.borderr{
  border: 2px solid red;
}


@keyframes slide-up {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes image{
  from {
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

@keyframes social{
  from {
    transform: translateY(100%);
    opacity: 0;
    width: 10px;
  }
  to {
    transform: translateY(0);
    opacity: 1;
    width: 48px;
  }
}

@keyframes modal{
  from {
    transform: translateY(-360%);
  }
  to {
    transform: translateY(0);
  }
}

.animate-slide-up {
  animation: slide-up 0.8s ease-out forwards;
}

.animate-image{
  animation-name: image;
  animation-duration: 3s;
  transition-duration: 3000ms;
  animation-iteration-count: 1;
}

.animate-phone{
  animation-name: image;
  animation-duration: 1s;
  transition-duration: 100ms;
  animation-iteration-count: 1;
}
.animate-social{
  animation-name: social;
  animation-duration: 2s;
}

.animate-modal{
  animation: modal 1s ease-in-out;
}

input[type="range"]::-webkit-slider-thumb {
  border: 1px solid #00001e;
  height: 16px;
  width: 16px;
  border-radius: 15px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  position: relative;
  pointer-events: all;
  -webkit-tap-highlight-color: transparent;
}

input[type="range"]::-moz-range-thumb {
  border: 1px solid #00001e;
  height: 16px;
  width: 16px;
  border-radius: 15px;
  background: #ffffff;
  cursor: pointer;
  position: relative;
}

.zoom-image {
  transition: transform 1000ms ease;
}

.zoom-image:hover {
  transform: scale(1.4) rotate(15deg);
}
.horizontal-scroll::-webkit-scrollbar {
  height: 15px;
}
.horizontal-scroll::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 100vw;
  margin-block: 0.5em;
}
.horizontal-scroll::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 100vw;
  border: 0.25em solid white;
  visibility: hidden;
}
.horizontal-scroll:active::-webkit-scrollbar-thumb,
.horizontal-scroll:focus::-webkit-scrollbar-thumb,
.horizontal-scroll:hover::-webkit-scrollbar-thumb {
    visibility: visible;
}



/* Add these styles to your CSS file */
.no-scrollbar {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  
}

.no-scrollbar::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

/* Hide the default radio button */
input[type="radio"] {
  appearance: none; /* Remove default styling */
  border: 2px solid #5D3A00; /* Border color for unselected radio button */
  border-radius: 50%;
  width: 20px; /* Size of the radio button */
  height: 20px; /* Size of the radio button */
  position: relative;
  cursor: pointer;
}

/* Custom radio dot */
input[type="radio"]:checked::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px; /* Size of the dot */
  height: 12px; /* Size of the dot */
  background-color: #5D3A00; /* Dot color */
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
