.rating-container {
    padding: 0 20px;
}

.rating-title {
    color: #703F07;
    font-family: 'jaldi', sans-serif;
    font-size: 24px;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 20px;
}

.rating-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 25px;
}

.rating-score p {
    font-size: 35px;
    font-weight: bold;
    display: flex;
    justify-content: space-around;
    line-height: 1;
}

.rating-bars {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 30px;
}

.rating-bar-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.rating-bar-item p {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 2px;
    white-space: nowrap;
    margin-right: 10px;
}

.rating-progress-background {
    width: 100%;
    height: 14px;
    background-color: #D4D4D5;
    border-radius: 10px;
    overflow: hidden;
}

.rating-progress-fill {
    height: 100%;
    border-radius: 10px;
}

.rating-count {
    margin-left: 5px;
    font-family: 'quicksand', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #000000b1;
}

@media (max-width: 768px) {
    .rating-score p {
        font-size: 28px;
    }

    .rating-bar-item p {
        font-size: 16px;
    }

    .rating-count {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .rating-content {
        flex-direction: column;
        align-items: center;
    }

    .rating-bars {
        margin-left: 0;
        width: 100%;
    }

    .rating-score p {
        font-size: 24px;
        margin-bottom: 10px;
    }

    .rating-bar-item p {
        font-size: 14px;
    }

    .rating-progress-background {
        height: 12px;
    }

    .rating-count {
        font-size: 12px;
    }
}
